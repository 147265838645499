import { reactive, toRefs, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { useDebounce } from "@/hooks/core/useDebounce";
import API from "@/api/report/index";
import { useMessage } from "@/hooks/web/useMessage";
import dayjs from "dayjs";
import { Search } from "@element-plus/icons-vue";
export default {
  name: "TrackinfoRecord",
  components: {
    // 自定义组件
    BasicTemplate: defineAsyncComponent(() => import("@/components/BasicTemplateTablePage/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    TreeSelect: defineAsyncComponent(() => import("@/components/TreeSelect/TreeSelect.vue")),
    AlarmInfo: defineAsyncComponent(() => import("./components/AlarmInfo.vue")),
    TrackinfoEchart: defineAsyncComponent(() => import("./components/TrackinfoEchart.vue"))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      temperatureEchartRefs: null,
      type: true,
      loading: false,
      tableLoading: false,
      total: 0,
      current: 1,
      pageSize: 50,
      value: 1,
      infoVisible: false,
      activeInfo: {},
      searchForm: {
        orgId: null,
        objectname: '',
        tracktype: 2,
        time: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")]
      },
      tableData: [],
      tableColumns: [{
        title: "序号",
        align: "center",
        key: "index",
        width: 60,
        slots: {
          customRender: "index"
        },
        visible: true
      }, {
        title: "设备名",
        align: "center",
        key: "objectname",
        visible: true,
        width: 140
      }, {
        title: "所属单位",
        align: "center",
        key: "holdname",
        visible: true,
        width: 150
      }, {
        title: "最大速度",
        align: "center",
        key: "speed",
        width: 100,
        slots: {
          customRender: 'speed'
        },
        visible: true
      }, {
        title: "行程类型",
        align: "center",
        key: "tracktype",
        slots: {
          customRender: 'tracktype'
        },
        visible: true,
        width: 120
      },
      // {
      //   title: "测温设备类型",
      //   align: "center",
      //   key: "deviceType",
      //   visible: true,
      //   width: 140
      // },
      {
        title: "行驶里程",
        align: "center",
        key: "mileage",
        slots: {
          customRender: 'mileage'
        },
        visible: true,
        width: 140
      }, {
        title: "开始时间",
        align: "center",
        key: "totaldate",
        width: 140,
        visible: true
      }, {
        title: "结束时间",
        align: "center",
        key: "endtime",
        width: 140,
        visible: true
      }]
    });
    const onSearch = async () => {
      //console.log(refData.searchForm.alarmTime)
      if (refData.searchForm.time === null) {
        refData.searchForm.time = ["", ""];
      }
      refData.loading = true;
      const params = {
        holdid: refData.searchForm.orgId,
        objectname: refData.searchForm.objectname,
        tracktype: refData.searchForm.tracktype,
        beginTime: refData.searchForm.time.length > 1 ? `${refData.searchForm.time[0]} 00:00:00` : "",
        endTime: refData.searchForm.time.length == 2 ? `${refData.searchForm.time[1]} 23:59:59` : "",
        pageNo: refData.current,
        pageSize: refData.pageSize
      };
      const regPhone = /^1[3456789]\d{9}$/;
      try {
        if (params.beginTime == " 00:00:00") throw {
          msg: "开始时间不能为空"
        };
        if (params.endTime == " 23:59:59") throw {
          msg: "结束时间不能为空"
        };
        if (!params.holdid) throw {
          msg: "请选择组织或单位"
        };
        const {
          data,
          msg,
          code
        } = await API.trackinfoPage(params);
        refData.total = data.total;
        refData.tableData = data.list;
        refData.loading = false;
      } catch (error) {
        refData.loading = false;
        message.warning(error.msg);
      }
    };
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //清空
    const handleEmpty = () => {
      refData.searchForm = {
        orgId: null,
        objectname: '',
        tracktype: 2,
        time: ['', '']
      };
    };
    //导出
    const handleExport = async () => {
      refData.tableLoading = true;
      const params = {
        holdid: refData.searchForm.orgId,
        objectname: refData.searchForm.objectname,
        tracktype: refData.searchForm.tracktype,
        beginTime: refData.searchForm.time.length > 1 ? `${refData.searchForm.time[0]} 00:00:00` : "",
        endTime: refData.searchForm.time.length == 2 ? `${refData.searchForm.time[1]} 23:59:59` : ""
      };
      await API.trackinfoExportExcel(params);
      refData.tableLoading = false;
    };
    //组织数回调
    const handleClick = data => {
      if (data.type == "org") {
        refData.searchForm.orgId = data.id;
      } else {
        refData.searchForm.orgId = data.parentId;
      }
      if (refData.type) {
        debounceOnSearch();
      } else {
        handleSwitch(false);
      }
    };
    //切换报表与统计
    const handleSwitch = onoff => {
      refData.type = onoff;
      if (!onoff) {
        refData.tableLoading = true;
        setTimeout(() => {
          refData.temperatureEchartRefs.loading = true;
          refData.temperatureEchartRefs.orgId = refData.searchForm.orgId != null ? refData.searchForm.orgId : null;
          refData.temperatureEchartRefs.time = refData.searchForm.time;
          refData.temperatureEchartRefs.getData();
          refData.tableLoading = false;
        }, 600);
      } else {
        refData.current = 1;
        debounceOnSearch();
      }
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleEmpty,
      handleExport,
      handleClick,
      handleSwitch,
      Search
    };
  }
};