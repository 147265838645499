import { GET, exportFile, POST, POSTBody,FORM ,Download} from '../apiFn';
import url from '../url';
import APIType from './type';

export default {
  /**
   * 报警日志分页查询
   */
  getAlarmLog: (params: APIType.getAlarmLogParam): Promise<APIType.getAlarmLogRes> =>
  GET(url.getAlarmLog, params),
  /**
   * 报警日志导出
   */
 
  exportAlarmList: (params: APIType.getAlarmLogParam): Promise<any> =>
  Download(url.exportAlarmList, params),

  /**
   * 指令下发记录导出
   */
  exportCmdist: (params: APIType.getCmdistParam): Promise<any>  =>
  Download(url.exportCmdist, params),
  
  /**
   * 指令下发记录分页查询
   */
  getCmdist: (params: APIType.getCmdistParam): Promise<APIType.getCmdistRes> =>
  GET(url.getCmdist, params),

  /**
   * 操作日志分页查询
   */
  operateLog: (params: APIType.operateLogParam): Promise<any> =>
  GET(url.operateLog, params),

  /**
   * 操作日志导出
   */
  operateExcel: (params: APIType.operateLogParam): void =>
  exportFile(url.operateExcel, params),

  /**
   * 体温导出
   */
  trackinfoExportExcel: (params: APIType.trackinfoPageParam): void =>
  exportFile(url.trackinfoExportExcel, params),

  /**
   * 体温分页查询
   */
  trackinfoPage: (params: APIType.trackinfoPageParam): Promise<APIType.trackinfoPageRes> =>
  GET(url.trackinfoPage, params),

  /**
   * 体温上报数据总览
   */
  trackinfoStatistics: (params: APIType.trackinfoStatisticsParam): Promise<APIType.trackinfoStatisticsRes> =>
  GET(url.trackinfoStatistics, params),

  /**
   * 体温图表处理APIType.uploadPicRes
   */
  uploadPic: (params: APIType.uploadPicParam): Promise<any> =>
  FORM(url.uploadPic, params),

  
  /**
   * 通话记录
   */
  callRecords: (params:APIType.callRecordsParam): Promise<APIType.callRecordsRes> =>
  GET(url.callRecords,params),

  
  /**
   * 导出通话记录
   */
  exportCallRecords: (params:APIType.callRecordsParam): void =>
  exportFile(url.exportCallRecords,params),

  
  /**
   * 分页查询步数信息
   */
  pagePcStep: (params:APIType.pagePcStepParam): Promise<APIType.pagePcStepRes> => 
  GET(url.pagePcStep,params),
  
  /**
   * 根据imei获取汇总信息
   */
  callTotal: (params:APIType.callRecordsParam): Promise<APIType.callTotalRes> => 
  GET(url.callTotal,params),
  
  /**
   * 根据分页数据获取对应的统计信息
   */
  statisticsLog: (params:APIType.callRecordsParam): Promise<APIType.statisticsLogRes> => 
  GET(url.statisticsLog,params),

};
